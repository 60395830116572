import { environment as base } from '@wingstop/environments/environment-base';

export const environment = {
  ...base,
  envName: 'dev',

  ecomm: {
    baseUrl: 'https://ecomm.dev.us.regional.dev.cf.10piececombo.com',
    menuUrl: 'https://ecomm.dev.us.regional.dev.cf.10piececombo.com',
    webSocketUrl: 'wss://websocket.dev.us.regional.dev.cf.10piececombo.com'
  },
  ngfeBaseUrl: 'https://dev.dev.ordering.wingstop.com',
};
